export const experienceData = [  
    {
        id: 1,
        company: 'Certsys',
        jobtitle: 'System Analyst',
        startYear: '2024',
        endYear: 'present'
    },    
    {
        id: 2,
        company: 'Engtech',
        jobtitle: 'Full-Stack Web Developer',
        startYear: '2023',
        endYear: '2024'
    },
    {
        id: 3,
        company: 'Soma Group - Hering, Farm, Animale, Fabula',
        jobtitle: 'Front-End Web Developer',
        startYear: '2022',
        endYear: '2023'
    },
 /*    {
        id: 4,
        company: 'EMS - Pharmaceutical Industry - São Paulo - Brazil',
        jobtitle: 'Scientist',
        startYear: '2019',
        endYear: '2022'
    }, */
]