export const educationData = [
    {
        id: 1,
        institution: 'IBM',
        course: 'DevOps and Software Engineering',
        startYear: '2023',
        endYear: 'Present'
    }, 
    {
        id: 2,
        institution: 'Uninter',
        course: 'Degree in System Analysis',
        startYear: '2023',
        endYear: '2025'
    },
    {
        id: 3,
        institution: 'Federal University of São Carlos - UFSCar - Brazil',
        course: 'Doctor Degree in Science',
        startYear: '2013',
        endYear: '2018'
    },
]