import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { ThemeContext } from "./contexts/ThemeContext";
import { Main, BlogPage, ProjectPage } from "./pages";
import { BackToTop } from "./components";
import ScrollToTop from "./utils/ScrollToTop";

import "./App.css";

function App() {
  const { theme } = useContext(ThemeContext);

  console.log("%cAmanda Website", `color:${theme.primary}; font-size:50px`);
  console.log(
    "%chttps://github.com/amandajaquino",
    `color:${theme.tertiary}; font-size:20px`
  );
  // console.log = console.warn = console.error = () => {};

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/projects" element={<ProjectPage />} />

          {/* Use Navigate inside a Route */}
          <Route
            path="/"
            element={
              <>
                <Navigate to="/blog" />
                <Main />
              </>
            }
          />
        </Routes>
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
