import resume from '../assets/pdf/resume.pdf'
import imgPrincipal from '../assets/jpg/principal-image.jpg'

export const headerData = {
    name: 'Amanda Aquino',
    title: "Web Developer",
    description:"Empowering globally through impactful digital solutions, I aim to develop applications for business, education, healthcare, and communication needs. Let's innovate and elevate user experiences together!",
    image: imgPrincipal,
    resumePdf: resume
}
