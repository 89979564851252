export const achievementData = {
    bio : "Lattest certifications",
    achievements : [
        {
            id : 1,
            title : 'Developing Back-End Apps with Node.js and Express',
            //details : 'Google Cloud certifications validate your expertise and showcase your ability to transform businesses with Google Cloud technology.',
            date : '2023',
            field : 'Back-End',
            image : 'https://upload.wikimedia.org/wikipedia/commons/5/51/IBM_logo.svg'
        },
        {
            id : 2,
            title : 'React Basics and Advanced - 52 hours',
            //details : 'Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.',
            date : '2023',
            field : 'Front-End',
            image : 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Meta-Logo.png'
        },       
        {
            id : 3,
            title : 'Programming with JavaScript - 48 hours',
            //details : 'Validate technical skills and cloud expertise to grow your career and business.',
            date : '2023',
            field : 'Programming',
            image : 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Meta-Logo.png'
        },
        
        {
            id : 4,
            title : 'Orange Tech +',
            //details : 'Google Cloud certifications validate your expertise and showcase your ability to transform businesses with Google Cloud technology.',
            date : '2022',
            field : 'Front-End',
            image : 'https://hermes.digitalinnovation.one/files/assets/e41313e0-53f7-4306-8791-09ca800fb469.png'
        },
      

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/