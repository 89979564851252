export const blogData = [
    {
        id: 1,
        title: 'React Basics - Data and State in ReactJS: Parent to Child and Hooks (useState, useReducer and Context API) ',
        description: 'This resume highlights my accomplishments and learning outcomes from the React Basics Week 2 course.',
        date: 'Jul 18, 2023',
        image: 'https://blogger.googleusercontent.com/img/a/AVvXsEgkEUJlOuQ6cpN2slyfD1oULoye026uRzewHIcfbRj9lQbRkOKuv9DLEAV25L6AKx7KZNjAKg81exqr3j2I1Q6DqodhxF50edrJnUtVtw2f9FLOtvGX_7g-yVG0MdYc1PYDFWBL8676Yrk30q4-LLJe4gaqbpEy87zzq2rTkc-vbrobITog-10V3UzJ2fQ',
        url: 'https://amandadeveloper.blogspot.com/2023/07/react-basics-week-2-by-meta-data-and.html'
    },
     {
        id: 2,
        title: 'React Basics - Navigation, Updates and assets',
        description: 'This resume highlights my accomplishments and learning outcomes from the React Basics Week 3 course',
        date: 'Jul 23, 2023',
        image: 'https://blogger.googleusercontent.com/img/a/AVvXsEi3Q7ULFCpUUAOW2XxJryEKGvWTOie6ThP_dDgQoBh4ZiU3YRToVdZQ0Xf54lK64SJu9TxMjKGweZ2T3c9VN6pdJAVmFD1gbSA-DN98mOb-PS_udexFKbmapthZKeMXUogAKP-p6tPntjrMvPClNW3C5pkO3Wj7a6qOoFCNcvE8AOT2qhCOLZ0oAxBMkpU',
        url: 'https://amandadeveloper.blogspot.com/2023/07/react-basics-week-3-by-meta-navigation.html'
    },
    {
        id: 3,
        title: 'How to deploy a full stack app with react and node js',
        description: 'Here is a step by step guide on how to deploy a full stack app with react and node js.',
        date: 'Jul 23, 2023',
        image: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjQPHzfg1nyft0VHJrwuWVhlaCY-jwGE_1ooxEb9G7WE0u3Y62N-NVAZsRYhtt8_Hs2G2GPjil9rRu24DALf5TAxoONZ5wV15iXcEKrj-x-p-hUcfJVNtdFOBMP7HwhQzt0dVXE-tS5oXVGUhTWLirdBMtvIsytlXJfl-tsxEfbcpMI8IP0GvHH7G0ROUA/s320/steps-website.png',
        url: 'https://amandadeveloper.blogspot.com/2023/07/how-to-deploy-full-stack-app-with-react.html'
    },
   /* {
        id: 4,
        title: 'CHECK INTERNET SPEED FROM THE COMMAND LINE',
        description: 'Want to check your internet speed from the terminal? Here\'s the perfect way to the that.',
        date: 'Sep 17, 2020',
        image: 'https://1.bp.blogspot.com/-XTVMsZP3HaM/Xxpl394txlI/AAAAAAAABws/zovXWiuNGg4TzBwYYVFfJ1ixDb3JfVokgCNcBGAsYHQ/w400-h195/speedtest.png',
        url: 'https://hackzism.blogspot.com/2020/07/check-internet-speed-from-command-line.html'
    },
    {
        id: 5,
        title: 'GET MICHAEL\'S OR TREVOR\'S VEHICLES FULLY UPGRADED IN GTA V FOR FREE',
        description: 'In case you haven\'t heard of this game, GTA stands for \'Grand Theft Auto\'. It is an open-world game published by Rockstar Games.',
        date: 'Oct 1, 2020',
        image: 'https://1.bp.blogspot.com/-jhdtUBIRD_s/XxLFKQuRz9I/AAAAAAAABsM/mClfbfDFKvsUwA7Wyi25Lzu3DEmL2lxrACNcBGAsYHQ/s1919/gta.jpg',
        url: 'https://hackzism.blogspot.com/2020/07/how-can-you-get-michaels-or-trevors.html'
    }, */
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/